/**
 * 팝업창을 띄운다.
 * @param {string} url
 * @param {int|float} popWidth
 * @param {int|float} popHeight
 * @returns {void}
 */
window.popupOpen = function popupOpen(url, popWidth, popHeight, popTop, popLeft, name)
{
    var screenWidht = screen.availWidth,
        screenHeight = screen.availHeight;

    // 너비가 1보다 작고 0보다 큰 유리수인 경우, 비율로 계산한다.
    if (popWidth < 1 && popWidth > 0)
    {
        popWidth = parseInt(screenWidht * popWidth);
    }

    // 높이가 1보다 작고 0보다 큰 유리수인 경우, 비율로 계산한다.
    if (popHeight < 1 && popHeight > 0)
    {
        popHeight = parseInt(screenHeight * popHeight);
    }

    if (!popTop)
    {
        popTop = 10;
    }

    if (!popLeft)
    {
        popLeft = 10;
    }

    if (!name)
    {
        name = "";
    }

    return window.open(url, name, "scrollbars=1,resizable=1,width=" + popWidth + ",height=" + popHeight + ",top=" + popTop + ",left=" + popLeft);
}

window.numberFormat = function numberFormat(str)
{
    var expr = /^(-?[0-9]+)([0-9]{3})/;
    str = str + "";
    while(expr.test(str)) {
        str = str.replace(expr,  "$1,$2");
    }

    return str;
}
