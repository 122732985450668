$.datepicker.setDefaults({
    closeText: '닫기',
    prevText: '이전달',
    nextText: '다음달',
    currentText: '오늘',
    monthNames: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
    monthNamesShort: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
    dayNames: ['일','월','화','수','목','금','토'],
    dayNamesShort: ['일','월','화','수','목','금','토'],
    dayNamesMin: ['일','월','화','수','목','금','토'],
    weekHeader: 'Wk',
    dateFormat: 'yy-mm-dd',
    firstDay: 0,
    isRTL: false,
    showMonthAfterYear: true,
    yearSuffix: '',
    changeMonth: true,
    changeYear: true,
    yearRange: "c-100:c+10",
    minDate: 0
});

// -js-datepicker
$("input[type='text'].-js-datepicker").datepicker();

$(document).on("click", ".-js-open-in-popup", function (e)
{
    e.preventDefault();

    var self = $(this);
    var selfTagName = self.prop('tagName');
    var href = this.href;

    if (selfTagName === 'INPUT')
    {
        href = self.data('href');
    }

    var width = parseFloat(self.data("popup-width"));
    var height = parseFloat(self.data("popup-height"));
    var top = parseFloat(self.data("popup-top"));
    var left = parseFloat(self.data("popup-left"));

    if (!width)
    {
        width = 800;
    }

    if (!height)
    {
        height = 0.98;
    }

    if (!top)
    {
        top = 10;
    }

    if (!left)
    {
        left = left;
    }

    window.popupOpen(href, width, height, top, left);
});

$(document).on('click', '.-js-daum-postcode-button', function (e) {
    var self = $(this);
    var dataZipcode = self.data('zipcode');
    var dataAddress = self.data('address');
    var dataCallback = self.data('callback');
    var zipcodeInput = $('input[name="' + dataZipcode + '"]');
    var addressInput = $('input[name="' + dataAddress + '"]');

    new daum.Postcode({
        oncomplete: function(data) {
            var roadAddr = data.roadAddress; // 도로명 주소 변수

            if (data.buildingName)
            {
                roadAddr = roadAddr + " (" + data.buildingName + ")";
            }

            zipcodeInput.val(data.zonecode);
            addressInput.val(roadAddr);

            window[dataCallback](data.sido, data.sigungu, data.bname);
        }
    }).open();
});
