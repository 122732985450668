require('./bootstrap');

var jsUserLogoutButton = $('.-js-user-logout-button');
var jsUserLogoutForm = $('.-js-user-logout-form');

jsUserLogoutButton.click(function () {
    jsUserLogoutForm.submit();
});

var jsUserDeleteButton = $('.-js-user-delete-button');
var jsUserDeleteForm = $('.-js-user-delete-form');

jsUserDeleteButton.click(function () {
    if (confirm('정말 탈퇴하시겠습니까?'))
    {
        jsUserDeleteForm.submit();
    }
});

var jsMemberInfoSame = $('.-js-member-info-same');

jsMemberInfoSame.change(function () {
    var self = $(this);
    var fromNameInput = $('input[name="from_name"]');
    var fromPhoneInput = $('input[name="from_phone"]');
    var toNameInput = $('input[name="to_name"]');
    var toPhoneInput = $('input[name="to_phone"]');

    if (self.is(':checked'))
    {
        toNameInput.val(fromNameInput.val());
        toPhoneInput.val(fromPhoneInput.val());
    }
    else
    {
        toNameInput.val('');
        toPhoneInput.val('');
    }
});
